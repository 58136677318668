import { useNavigate, useSearchParams } from 'react-router-dom';

import { useEffect, useState } from 'react';
import ButtonAdd from 'components/Global/ButtonAdd';
import React from 'react';
import Tabs, { TabPane } from 'components/Global/Tabs';
import DriverList from './DriverList';
import { useDispatch } from 'react-redux';
import { resetSelectedDriver } from 'features/drivers/slice';

const DriverComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();

  const tabActive = searchParams.get('tab-active');

  const handleAdd = () => {
    switch (tabActive) {
      case 'Driver':
        dispatch(resetSelectedDriver());
        return navigate('/driver/add-driver');
      default:
    }
  };

  useEffect(() => {
    if (!tabActive) {
      setSearchParams({ 'tab-active': 'Driver' });
    } else {
      setActiveTab(tabActive);
    }
  }, [tabActive]);

  return (
    <div className="driver">
      <ButtonAdd onClick={handleAdd} label={`Tambah ${activeTab}`} />

      <Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
        <TabPane label="Driver">
          <DriverList />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default DriverComponent;
