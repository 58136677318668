const PAGE_ACTIVE = Object.freeze({
  '/': 'Dashboard',
  '/dashboard-refferal': 'Dashboard Refferal',
  '/transaction-report': 'Rekap Transaksi',
  '/without-driver': 'Lepas Kunci',
  '/with-driver': 'Dengan Supir',
  '/airport-transfer': 'Airport Transfer',
  '/tour': 'Tour',
  '/user-customer': 'User Customer',
  '/user-influencer': 'User Influencer',
  '/vehicles': 'Kendaraan',
  '/tour-schedule': 'Jadwal Tour',
  '/rental-area': 'Daerah Sewa',
  '/return-and-delivery': 'Pengantaran dan Pengembalian',
  '/garages': 'Garasi',
  '/additional': 'Additional',
  '/deposit': 'Deposit',
  '/setting-dp': 'Setting DP',
  '/zone': 'Zona',
  '/language': 'Bahasa',
  '/term-and-condition': 'Syarat & Ketentuan',
  '/promo': 'Promo',
  '/voucher': 'Voucher',
  '/banner': 'Banner',
  '/schedule-price': 'Schedule Price',
  '/business-partner': 'Partner Bisnis',
  '/schedule-price-airport': 'Schedule Price Airport',
  '/selling-point': 'About Us',
  '/notification-inbox': 'Notifikasi Inbox',
  '/notification': 'Notifikasi',
  '/contact-us': 'Hubungi Kami',
  '/car-brands': 'Merk Mobil',
  '/roles': 'Role',
  '/assign-roles': 'Assign Role',
  '/driver-task': 'Driver Task',
  '/task-admin': 'Task Admin',
  '/overtime': 'Overtime',
  '/overtime-rule': 'Aturan Overtime',
  '/category': 'Kategori Mobil',
  '/airport-transfer-car': 'Airport Transfer Car',
  '/min-rent': 'Minimal Rental',
  '/driver': 'Driver',
});

export default PAGE_ACTIVE;
