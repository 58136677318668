import React from 'react';
import { ReactComponent as EllipseIcon } from 'icons/ellipse-icon.svg';
import { useLocation, useSearchParams } from 'react-router-dom';

export const TabPane = ({ activeTab, setActiveTab, icon, label, read, showBorder, positionEnd = false }) => {
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();

  const onClickHandler = () => {
    if (location.pathname === '/without-driver') {
      setSearchParams({ status: label });
      setActiveTab(label);
    }

    if (location.pathname === '/schedule-price') {
      setSearchParams({ 'tab-active': label });
      setActiveTab(label);
    }

    if (location.pathname === '/promo') {
      setSearchParams({ 'tab-active': label });
      setActiveTab(label);
    }

    if (location.pathname === '/airport-transfer-car') {
      setSearchParams({ 'tab-active': label });
      setActiveTab(label);
    }

    if (location.pathname === '/business-partner') {
      setSearchParams({ 'tab-active': label });
      setActiveTab(label);
    }

    if (location.pathname === '/term-and-condition') {
      setSearchParams({ 'tab-active': label });
      setActiveTab(label);
    }

    if (location.pathname === '/overtime') {
      setSearchParams({ tab: label });
      setActiveTab(label);
    }

    if (location.pathname === '/promo') {
      setSearchParams({ 'tab-active': label });
      setActiveTab(label);
    }

    if (location.pathname === '/user') {
      setSearchParams({ 'tab-active': label });
      setActiveTab(label);
    }

    if (location.pathname === '/driver') {
      setSearchParams({ 'tab-active': label });
      setActiveTab(label);
    }

    setActiveTab(label);
  };

  return (
    <li
      className={`tab-list-item ${activeTab === label ? 'tab-list-active' : ''} ${
        showBorder && activeTab !== label ? 'tab-list-bordered' : ''
      } ${positionEnd && 'tab-list-end-list'}`}
      onClick={onClickHandler}
    >
      <div className={icon ? 'tab-gap' : ''}>
        {icon && icon}
        <span
          className={`tab-label ${activeTab === label ? 'active' : ''} ${
            showBorder && activeTab !== label ? 'bordered' : ''
          } ${positionEnd && 'tab-list-end-list'}`}
        >
          {label}
        </span>
        {read && <EllipseIcon style={{ marginLeft: 3 }} />}
      </div>
    </li>
  );
};

const Tabs = ({ children, activeTab, setActiveTab, showBorder = false }) => {
  const childrenTab = !children.length ? [children] : [...children];

  const childrenWithProps = React.Children.map(childrenTab, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { activeTab, setActiveTab, showBorder });
    }
    return child;
  });

  return (
    <div className="tabs">
      <ol className="tab-list">{childrenWithProps}</ol>
      <div className="tab-content">
        {childrenTab.map((child) => {
          if (child.props.label !== activeTab) return undefined;
          return child.props.children;
        })}
      </div>
    </div>
  );
};

export default Tabs;
