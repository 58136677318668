import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { ReactComponent as PointsIcon } from 'icons/points-icon.svg';
import { Button, InputField, TableWrapper } from 'components/Global';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { indonesianDateFormat } from 'utils/helpers';
import { checkEmptyObject, setCurrency } from 'utils/functionality';
import countryCodes from 'utils/country-codes.json';
import clsx from 'clsx';
import { getCustomerData } from 'features/user/slice';
import {
  changeOrderStatus,
  changePaymentType,
  fetchOrderByIdWithApproval,
  getOrderAddOnsById,
} from 'features/orders/actions';
import InputViolation from 'components/Global/InputViolation';
import { getDetailAccountBank } from 'features/banks/actions';
import { getCourierDetail } from 'features/couriers/actions';
import { downloadOrderDetail } from 'features/order-detail-pdf/actions';
import { ReactComponent as ChevronDown } from '../../../../icons/chevron-down.svg';
import { getTaskDetail } from 'features/driver-task/actions';
import { getLocationTimezone } from 'utils/getTimezone';
import { getPaymentMethodString } from 'utils/getPaymentMethod';
import { ReactComponent as CarChair } from 'icons/car-chair-icon.svg';
import Tabs, { TabPane } from 'components/Global/Tabs';
import InputAdditionals from 'components/Global/InputAdditionals';
import ReactDOM from 'react-dom';
import PriceDifferenceModal from 'components/Modals/PriceDifferenceModal';
import { getPaymentMethods } from 'features/payment/actions';
// import { getOrderAddon } from 'features/addon/actions';
import AddOnsField from 'components/Global/AddOnsField';
import { updateApprovalStatus } from 'features/approvals/actions';
import { ReactComponent as ReuploadIcon } from 'icons/reupload-icon.svg';

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES;

const DetailOrder = ({
  setShowBankTransferConfirmation,
  isPaymentValid,
  orderData: data,
  getFileName,
  imagePreviewHandler,
  transactionKey,
}) => {
  const { showToast, setShowConfirmation, setShowRejectOrderModal, setShowAssignDriver, setShowReviewIdentityModal } =
    useAppContext();

  const [selectedCountry, setSelectedCountry] = useState({
    name: '',
    code: '',
  });
  const [selectedDriver, setSelectedDriver] = useState(0);
  const [activeTab, setActiveTab] = useState('Data Sekarang');
  // ADDITIONAL STATE
  const [additionalList, setAdditionalList] = useState([]);
  const [additionalPrice, setAdditionalPrice] = useState(0);
  // VIOLIATION STATE
  const [violationList, setViolationList] = useState([]);
  const [violationPrice, setViolationPrice] = useState(0);
  const [oldOrderData, setOldOrderData] = useState({});
  const [newOrderData, setNewOrderData] = useState({});
  const [showPriceDifference, setShowPriceDifference] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const customer = useSelector(getCustomerData);
  const customerAccountBank = useSelector((state) => state.banks?.detailAccountBank ?? {});
  const couriersData = useSelector((state) => state.couriers.selectedCourier);
  // eslint-disable-next-line no-unused-vars
  const courierTask = useSelector((state) => state.driverTask.selectedTask);
  const paymentMethod = useSelector((state) => state.payment.data);
  const addOnsOrder = useSelector((state) => state.detailOrder.addOns);

  const findCountryByCode = (code) => countryCodes.find((item) => item.dial_code == code);
  const customerId = searchParams.get('customerId');

  useEffect(() => {
    dispatch(fetchOrderByIdWithApproval(transactionKey));
    dispatch(getOrderAddOnsById(transactionKey));
    dispatch(getPaymentMethods());
  }, [transactionKey]);

  useEffect(() => {
    if (checkEmptyObject(data)) return;
    setOldOrderData(data || {});
    setNewOrderData(data?.approval?.data || {});
  }, [data]);

  useEffect(() => {
    if (!customerId) return;

    dispatch(getDetailAccountBank(customerId));
  }, [customerId]);

  useEffect(() => {
    if (customer) {
      setSelectedCountry(findCountryByCode(data?.is_admin_creation ? data?.phone_country_code : customer?.phone_code));
    }
  }, [customer, data]);

  useEffect(() => {
    if (additionalList.length > 0) {
      setAdditionalPrice(() => additionalList.map((item) => item.value).reduce((acc, cVal) => acc + cVal, 0));
      return;
    }
    setAdditionalPrice(0);
  }, [additionalList]);

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(data, 'order_violations')) {
      if (data.order_violations !== null) {
        setViolationList(data.order_violations);
      }
    }
  }, [data]);

  useEffect(() => {
    if (violationList.length > 0) {
      setViolationPrice(() => violationList?.map((item) => item.cost).reduce((acc, cVal) => acc + cVal, 0));
      return;
    }
    setViolationPrice(0);
  }, [violationList]);

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(data, 'courier_task') === null) return;
    if (!data.courier_task?.user_id) return;
    if (data.order_status === 'COMPLETED' || data.order_status === 'FINISHED') {
      dispatch(getTaskDetail(data?.courier_task?.id));
    }
    dispatch(getCourierDetail(data?.courier_task?.user_id));
    setSelectedDriver(data?.courier_task?.user_id);
  }, [data]);

  // useEffect(() => {
  //   if (!Object.keys(courierTask).length) return;

  //   setSelectedDriver(courierTask?.courier_id);
  // }, [courierTask]);

  const handleProcess = () => {
    let prevStatus;
    let nextStatus;
    let confirmationMessage;
    let toastSuccessMessage;
    let toastErrorMessage;

    switch (data?.order_status) {
      case 'CHECKOUT':
        prevStatus = 'CHECKOUT';
        nextStatus = 'PAID';
        confirmationMessage = 'Apakah anda yakin ingin mengkonfirmasi transaksi?';
        toastSuccessMessage = 'Transaksi Berhasil Dikonfirmasi';
        toastErrorMessage = 'Transaksi Gagal Dikonfirmasi!';
        break;
      case 'PAID':
        prevStatus = 'PAID';
        nextStatus = 'COMPLETED';
        confirmationMessage =
          data.type === 'HALF'
            ? `Orderan ini menggunakan pembayaran DP ${
                data?.order_detail?.dp_rule?.formula_percentage?.value
                  ? `${data.order_detail.dp_rule.formula_percentage?.value}%`
                  : ''
              }. Sebelum melanjutkan transaksi, pastikan pelanggan telah membayar sisa DP terlebih dahulu. Tetap lanjutkan?`
            : 'Apakah anda yakin ingin memproses transaksi?';
        toastSuccessMessage = 'Transaksi Berhasil Diproses';
        toastErrorMessage = 'Transaksi Gagal Diproses!';
        break;
      case 'REVIEWING_IDENTITY':
        prevStatus = 'REVIEWING_IDENTITY';
        nextStatus = 'COMPLETED';
        confirmationMessage =
          data.type === 'HALF'
            ? `Orderan ini menggunakan pembayaran DP ${
                data?.order_detail?.dp_rule?.formula_percentage?.value
                  ? `${data.order_detail.dp_rule.formula_percentage?.value}%`
                  : ''
              }. Sebelum melanjutkan transaksi, pastikan pelanggan telah membayar sisa DP terlebih dahulu. Tetap lanjutkan?`
            : 'Apakah anda yakin ingin memproses transaksi?';
        toastSuccessMessage = 'Transaksi Berhasil Diproses';
        toastErrorMessage = 'Transaksi Gagal Diproses!';
        break;
      default:
        throw new Error('order status not found!');
    }

    let payload;

    payload = {
      prevStatus,
      nextStatus,
      transaction_key: data?.transaction_key,
    };

    setShowConfirmation({
      message: confirmationMessage,
      show: true,
      onClick: async () => {
        try {
          if (data?.order_approval_status?.includes('UPDATE_ORDER')) {
            await dispatch(updateApprovalStatus({ status: 'approved', id: data?.approval?.id, skip: true })).unwrap();
          } else {
            await dispatch(changeOrderStatus(payload)).unwrap();
          }
          showToast({ type: 'success', message: toastSuccessMessage });

          // change DP 50% to Lunas
          if (data.type === 'HALF' && data.order_status === 'PAID') {
            await dispatch(changePaymentType(data?.transaction_key)).unwrap();
          }
        } catch (err) {
          showToast({ type: 'error', message: toastErrorMessage });
        } finally {
          navigate(-1);
        }
      },
    });
  };

  /**
   * komponen ini untuk merender tombol Proses di modal detail order
   * untuk tabel Konfirmasi dan tabel Belum Diproses saja
   */
  const RenderedProcessButton = () => {
    const isOrderApproval = data?.approval !== null;
    const isManualTransfer =
      paymentMethod?.find(
        (item) =>
          item.id ===
          (isOrderApproval
            ? data?.approval?.data?.disbursement?.payment_method_id
            : data?.disbursement?.payment_method_id),
      )?.method === 'Manual Transfer';
    const showButtonForConfirmation =
      isManualTransfer &&
      data.order_status === 'CHECKOUT' &&
      data?.order_approval_status !== 'WAITING_APPROVAL_DELETE_ORDER';
    const showButtonForUnprocess =
      (data.order_status === 'PAID' || data.order_status === 'REVIEWING_IDENTITY') &&
      !customer?.PersonalInfos?.need_review_sim &&
      !customer?.PersonalInfos?.need_review_ktp &&
      !data.order_approval_status;

    if (showButtonForConfirmation) {
      return (
        <Button
          variant="success"
          className={clsx('button', !isPaymentValid && 'button--disabled')}
          width={208}
          size="sm"
          disabled={!isPaymentValid}
          onClick={handleProcess}
        >
          Proses
        </Button>
      );
    } else if (showButtonForUnprocess) {
      return (
        <Button variant="success" className="button" width={208} size="sm" onClick={handleProcess}>
          Proses
        </Button>
      );
    } else {
      return null;
    }
  };

  const backHandler = () => {
    navigate(-1);
  };

  const assignDriverHandler = () => {
    if (selectedDriver) return;
    setShowAssignDriver(true);
  };

  const handleExportToPdf = async () => {
    try {
      await dispatch(downloadOrderDetail(data.transaction_key)).unwrap();
    } catch (error) {
      showToast({ type: 'error', message: 'Gagal Export ke PDF' });
    }
  };

  const timezone = !data?.order_detail?.loc_time_id ? '' : getLocationTimezone(data?.order_detail?.loc_time_id);

  return (
    <div className="detail-order">
      <div className="detail-order__back-btn" onClick={backHandler}>
        <LeftArrow />
        <p>Kembali</p>
      </div>
      <TableWrapper
        icon={<CarIcon fill="#009EF7" width="25px" height="25px" />}
        title="Lepas Kunci"
        showExportButton
        onExportToPdf={handleExportToPdf}
        CustomFilterComponent={
          Object.prototype.hasOwnProperty.call(data, 'order_approval_history') ? (
            <div className="btn-export">
              <Button variant="primary" onClick={() => setShowPriceDifference(true)}>
                Detail Perbedaan
              </Button>
            </div>
          ) : null
        }
      >
        {(data?.order_status === 'PAID' || data?.order_status === 'COMPLETED' || data?.order_status === 'FINISHED') &&
          !data.order_approval_status && (
            <div>
              <InputField
                icon={<ChevronDown />}
                label="Assign To"
                htmlFor="assign-driver"
                placeholder="Pilih Driver"
                value={couriersData?.name || ''}
                onClick={assignDriverHandler}
                iconPosition="end"
                readOnly
                disable={data?.order_status === 'COMPLETED' || data?.order_status === 'FINISHED'}
                className="assign-driver-input"
              />
            </div>
          )}
        {data?.order_approval_status ? (
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
            <TabPane label="Data Sekarang">
              <div className="detail-order__renter-detail">
                <div className="detail-order__renter-detail-title">
                  <h1>Detail Penyewa</h1>
                </div>
                <div className="detail-order__renter-detail-body">
                  <InputField label="Nama Lengkap" disabled value={newOrderData?.user_name || '-'} />
                  <InputField label="Email" disabled value={newOrderData?.email || '-'} />

                  <div className="detail-order__renter-detail-body__phone-number">
                    <InputField
                      label="No Handphone"
                      disabled
                      value={
                        newOrderData?.is_admin_creation ? newOrderData?.phone_country_code : customer?.phone_code || '-'
                      }
                      icon={
                        <img
                          src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                          width={26}
                          height={16}
                          alt={selectedCountry?.name + ' nation flag'}
                        />
                      }
                    />
                    <InputField value={newOrderData?.phone_number || '-'} disabled />
                  </div>

                  <InputField label="Negara" disabled value={selectedCountry?.name || '-'} />

                  <div className="detail-order__renter-detail-body__phone-number">
                    <div className="detail-order__renter-detail-body__phone-number-wrapper">
                      <InputField
                        label="Whatsapp"
                        disabled
                        value={
                          newOrderData?.is_admin_creation
                            ? newOrderData?.phone_country_code
                            : customer?.phone_code || '-'
                        }
                        icon={
                          <img
                            src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                            width={26}
                            height={16}
                            alt={selectedCountry?.name + ' nation flag'}
                          />
                        }
                      />
                      <div className="detail-order__renter-detail-body__image-wrapper"></div>
                    </div>
                    <InputField disabled value={newOrderData?.wa_number || '-'} />
                  </div>

                  <div />

                  <div className="detail-order__payment-detail-body__preview-image">
                    <InputField
                      label="KTP"
                      disabled
                      value={
                        data?.is_admin_creation && getFileName(data?.order_detail?.identity?.ktp) !== ''
                          ? getFileName(data?.order_detail?.identity?.ktp)
                          : customer?.PersonalInfos?.ktp
                          ? getFileName(customer?.PersonalInfos?.ktp)
                          : 'Belum Upload KTP'
                      }
                      className="preview-image-input"
                    />
                    {(data?.is_admin_creation && getFileName(data?.order_detail?.identity?.ktp !== '')) ||
                    customer?.PersonalInfos?.ktp ? (
                      <Button
                        bgColor="#D9D9D9"
                        textColor="#000000"
                        className="preview-image-btn"
                        onClick={() =>
                          imagePreviewHandler(
                            data?.is_admin_creation
                              ? data?.order_detail?.identity?.ktp
                              : IMAGE_BASE_URL + customer?.PersonalInfos?.ktp,
                          )
                        }
                      >
                        Lihat
                      </Button>
                    ) : null}
                  </div>

                  <div className="detail-order__payment-detail-body__preview-image">
                    <InputField
                      label="SIM"
                      disabled
                      value={
                        data?.is_admin_creation && getFileName(data?.order_detail?.identity?.sim) !== ''
                          ? getFileName(data?.order_detail?.identity?.sim)
                          : customer?.PersonalInfos?.sim
                          ? getFileName(customer?.PersonalInfos?.sim)
                          : 'Belum Upload SIM'
                      }
                      className="preview-image-input"
                    />
                    {(data?.is_admin_creation && getFileName(data?.order_detail?.identity?.sim) !== '') ||
                    customer?.PersonalInfos?.sim ? (
                      <Button
                        bgColor="#D9D9D9"
                        textColor="#000000"
                        className="preview-image-btn"
                        onClick={() =>
                          imagePreviewHandler(
                            data?.is_admin_creation
                              ? data?.order_detail?.identity?.sim
                              : IMAGE_BASE_URL + customer?.PersonalInfos?.sim,
                          )
                        }
                      >
                        Lihat
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="detail-order__rent-detail">
                <div className="detail-order__rent-detail-title">
                  <h1>Detail Sewa</h1>
                </div>
                <div className="detail-order__rent-detail-body">
                  <InputField
                    label="Lokasi Pengantaran"
                    disabled
                    value={newOrderData?.order_detail?.rental_delivery_location || '-'}
                  />
                  <InputField
                    label="Detail Lokasi Pengantaran"
                    disabled
                    value={newOrderData?.order_detail?.rental_delivery_location_detail || '-'}
                  />
                  {newOrderData?.order_detail?.flight_number ? (
                    <InputField
                      label="Nomor Penerbangan"
                      disabled
                      value={newOrderData?.order_detail?.flight_number || '-'}
                    />
                  ) : null}
                  {newOrderData?.order_detail?.landing_time ? (
                    <InputField
                      label="Waktu Mendarat"
                      disabled
                      value={newOrderData?.order_detail?.landing_time || '-'}
                    />
                  ) : null}

                  <InputField
                    label="Lokasi Pengambilan"
                    disabled
                    value={newOrderData?.order_detail?.rental_return_location || '-'}
                  />
                  <InputField
                    label="Detail Lokasi Pengambilan"
                    disabled
                    value={newOrderData?.order_detail?.rental_return_location_detail || '-'}
                  />

                  <InputField label="Tipe Sewa" disabled value={newOrderData?.order_type_id === 1 ? 'Harian' : '-'} />
                  <InputField label="Jenis Mobil" disabled value={newOrderData?.order_detail?.vehicle?.name ?? '-'} />

                  <div className="detail-order__rent-detail-body__rent-date">
                    <InputField
                      label="Tanggal Mulai"
                      disabled
                      value={
                        newOrderData?.order_detail?.start_booking_date
                          ? indonesianDateFormat(newOrderData?.order_detail?.start_booking_date)
                          : '-'
                      }
                    />
                    <InputField
                      label="Jam Mulai"
                      disabled
                      value={(newOrderData?.order_detail?.start_booking_time || '-') + ' ' + timezone}
                    />
                  </div>

                  <div className="detail-order__rent-detail-body__rent-date">
                    <InputField
                      label="Tanggal Selesai"
                      disabled
                      value={
                        newOrderData?.order_detail?.end_booking_date
                          ? indonesianDateFormat(newOrderData?.order_detail?.end_booking_date)
                          : '-'
                      }
                    />
                    <InputField
                      label="Jam Selesai"
                      disabled
                      value={(newOrderData?.order_detail?.end_booking_time || '-') + ' ' + timezone}
                    />
                  </div>

                  <div className="detail-order__rent-detail-body__rent-date">
                    <InputField
                      label="Plat Mobil"
                      disabled
                      value={newOrderData?.order_detail?.vehicle?.license_number ?? '-'}
                    />
                    <InputField
                      label="Jumlah Penumpang"
                      icon={<CarChair />}
                      iconPosition="start"
                      disabled
                      value={
                        newOrderData.order_detail?.passenger_number
                          ? `${newOrderData?.order_detail?.passenger_number} Orang`
                          : '0'
                      }
                    />
                  </div>

                  <div className="detail-order__rent-detail-body__rent-date">
                    <InputField
                      label="Kapasitas Koper"
                      disabled
                      value={newOrderData?.order_detail?.vehicle?.max_suitcase ?? '-'}
                    />
                    <InputField label="Koper yang dibawa" disabled value={newOrderData?.order_detail?.baggage ?? 0} />
                  </div>

                  <InputField
                    label="Overtime"
                    value={newOrderData?.over_time > 0 ? newOrderData?.over_time : 'Tidak Ada Overtime' || ''}
                    disabled
                    readOnly
                  />
                </div>
              </div>

              <div className="detail-order__voucher-detail">
                <div className="detail-order__voucher-detail-title">
                  <h1>Detail Deposit</h1>
                </div>
                <div className="detail-order__voucher-detail-body">
                  <InputField label="Nama Akun" disabled value={customerAccountBank.nama_rek || '-'} />
                  <InputField label="No Rekening" disabled value={customerAccountBank.no_rek || '-'} />
                  <InputField label="Nama Bank" disabled value={customerAccountBank.nama_bank || '-'} />
                </div>
              </div>

              <AddOnsField data={newOrderData?.addons} totalPrice={newOrderData?.addon_total_price} />

              <div className="detail-order__voucher-detail">
                <div className="detail-order__voucher-detail-title">
                  <h1>Voucher</h1>
                </div>
                <div className="detail-order__voucher-detail-body">
                  <InputField label="Nama Voucher" disabled value={newOrderData?.vouchers?.[0].name || '-'} />
                  <InputField label="Kode Voucher" disabled value={newOrderData?.vouchers?.[0].code || '-'} />
                  <InputField
                    label="Harga Potongan"
                    disabled
                    value={setCurrency(newOrderData?.vouchers?.[0].value) || '-'}
                  />
                </div>
              </div>

              <div className="detail-order__price-detail">
                <div className="detail-order__price-detail-title">
                  <h1>Rincian Biaya</h1>
                </div>
                <div className="detail-order__price-detail-body">
                  <InputField
                    label="Biaya Sewa Mobil"
                    disabled
                    value={setCurrency(newOrderData?.booking_price) || '-'}
                  />
                  <InputField label="Deposit" disabled value={setCurrency(newOrderData?.deposit) || '-'} />
                  <InputField
                    label="Deposit e-Toll"
                    disabled
                    value={setCurrency(newOrderData?.deposit_e_toll) || '-'}
                  />
                  <InputField
                    label="Pembayaran Dengan Point"
                    disabled
                    value={newOrderData?.point}
                    icon={<PointsIcon />}
                    iconPosition="start"
                  />
                  {newOrderData?.over_time_price > 0 && (
                    <InputField label="Overtime" disabled value={setCurrency(newOrderData?.over_time_price) || '-'} />
                  )}
                  {newOrderData?.one_day_order_charge > 0 && (
                    <InputField
                      label="One Day Charge"
                      disabled
                      value={setCurrency(newOrderData?.one_day_order_charge) || '-'}
                    />
                  )}
                  {newOrderData?.outside_operational_charge > 0 && (
                    <InputField
                      label="Outside Operational Hour Charge"
                      disabled
                      value={setCurrency(newOrderData?.outside_operational_charge) || '-'}
                    />
                  )}
                  {newOrderData?.exceed_passenger_price > 0 && (
                    <InputField
                      label="Exceed Max Passenger Charge"
                      disabled
                      value={setCurrency(newOrderData?.exceed_passenger_price) || '-'}
                    />
                  )}
                  <InputField
                    label="Biaya Lokasi Pengantaran"
                    disabled
                    value={setCurrency(newOrderData?.rental_delivery_fee) || '-'}
                  />
                  <InputField
                    label="Biaya Pengembalian"
                    disabled
                    value={setCurrency(newOrderData?.rental_return_fee) || '-'}
                  />
                  <InputField
                    label="Charge Payment Gateway Platform"
                    disabled
                    value={setCurrency(newOrderData?.disbursement?.total_amount_external_payment_fee) || '-'}
                  />
                  <InputField
                    label="Customer Service Platform"
                    disabled
                    value={setCurrency(newOrderData?.disbursement?.total_amount_customer_service_fee) || '-'}
                  />
                </div>
                <div className="detail-order__price-detail-body__input-violation">
                  <InputViolation
                    violationList={[]}
                    setViolationList={setViolationList}
                    totalPrice={newOrderData?.order_violations
                      ?.map((item) => item.cost)
                      ?.reduce((acc, cVal) => acc + cVal, 0)}
                    violationPrice={0}
                    data={newOrderData?.order_violations || []}
                    isOnEdit={false}
                  />
                </div>
                <div className="detail-order__price-detail-body__input-violation">
                  <InputAdditionals
                    additionalList={[]}
                    setAdditionalList={setAdditionalList}
                    totalPrice={newOrderData?.order_additional_fees
                      ?.map((item) => item.value)
                      ?.reduce((acc, cVal) => acc + cVal, 0)}
                    additionalPrice={0}
                    data={newOrderData?.order_additional_fees || []}
                    isOnEdit={false}
                  />
                </div>
                <div className="detail-order__price-detail-body__payment-type">
                  <div className="detail-order__price-detail-body">
                    <InputField
                      label="Tipe Pembayaran"
                      disabled
                      value={
                        newOrderData.type === 'HALF'
                          ? `Pembayaran DP ${
                              newOrderData?.order_detail?.dp_rule?.formula_percentage?.value
                                ? `${newOrderData.order_detail.dp_rule.formula_percentage.value}%`
                                : ''
                            }`
                          : 'Pembayaran Full'
                      }
                    />
                    <InputField
                      label={`Biaya Bayar DP ${
                        newOrderData?.order_detail?.dp_rule?.formula_percentage?.value
                          ? `${newOrderData.order_detail.dp_rule.formula_percentage.value}%`
                          : ''
                      }`}
                      disabled
                      value={newOrderData.type === 'HALF' ? setCurrency(newOrderData.down_payment) : '-'}
                    />
                    <InputField
                      label="Biaya selanjutnya yang harus dibayar"
                      disabled
                      value={newOrderData.type === 'HALF' ? setCurrency(newOrderData.remainder) : '-'}
                    />
                    {newOrderData.type === 'HALF' ? (
                      <InputField
                        label="Status Pembayaran DP"
                        disabled
                        value={newOrderData?.payment_time === null ? 'Belum Lunas' : 'Lunas' || '-'}
                      />
                    ) : null}
                  </div>
                </div>

                <div className="detail-order__price-detail-body__payment-total">
                  <div className="detail-order__price-detail-body">
                    <InputField
                      label="Total Keseluruhan"
                      disabled
                      value={setCurrency(newOrderData?.total_payment) || '-'}
                    />
                  </div>
                </div>
              </div>

              <div className="detail-order__payment-detail">
                <div className="detail-order__payment-detail-title">
                  <h1>Pembayaran</h1>
                </div>
                <div className="detail-order__payment-detail-body">
                  <InputField label="Status Pembayaran" disabled value={data?.order_status || '-'} />

                  <div className={clsx('detail-order__payment-detail-body__payment-method confirmation')}>
                    <InputField
                      label="Metode Pembayaran"
                      disabled
                      // value={data?.disbursement?.payment?.method ? data?.disbursement.payment.method : '-'}
                      value={getPaymentMethodString(data?.disbursement_recalculate)}
                    />
                    {data.order_status === 'CHECKOUT' &&
                    data?.order_approval_status !== 'WAITING_APPROVAL_DELETE_ORDER' ? (
                      <Button
                        height={44}
                        onClick={() => setShowBankTransferConfirmation(true)}
                        disabled={isPaymentValid}
                      >
                        Konfirmasi
                      </Button>
                    ) : null}
                  </div>

                  {data.order_status !== 'CHECKOUT' &&
                  data.order_status !== 'RECONFIRMATION' &&
                  Object.prototype.hasOwnProperty.call(data, 'disbursement') ? (
                    <>
                      <InputField
                        label="Nama Rekening"
                        disabled
                        value={
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.sender_name
                            : data?.disbursement?.sender_name || '-'
                        }
                      />

                      <InputField
                        label="Nama Bank"
                        disabled
                        value={
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.sender_bank_name
                            : data?.disbursement?.sender_bank_name || '-'
                        }
                      />

                      <div className="detail-order__payment-detail-body__preview-image">
                        <InputField
                          label="Foto Bukti Transfer"
                          disabled
                          value={getFileName(
                            Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                              ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                              : data?.disbursement?.disbursement_confirmation_image,
                          )}
                          className="preview-image-input"
                        />

                        <Button
                          bgColor="#D9D9D9"
                          textColor="#000000"
                          className="preview-image-btn"
                          onClick={() =>
                            imagePreviewHandler(
                              Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                                : data?.disbursement?.disbursement_confirmation_image,
                            )
                          }
                        >
                          Lihat
                        </Button>
                      </div>

                      <div />
                    </>
                  ) : null}
                </div>
              </div>

              <div className="detail-order__transaction-buttons">
                <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
                  Kembali
                </Button>

                <RenderedProcessButton />
              </div>
            </TabPane>
            <TabPane label="Data Sebelum">
              <div className="detail-order__renter-detail">
                <div className="detail-order__renter-detail-title">
                  <h1>Detail Penyewa</h1>
                </div>
                <div className="detail-order__renter-detail-body">
                  <InputField label="Nama Lengkap" disabled value={oldOrderData?.user_name || '-'} />
                  <InputField label="Email" disabled value={oldOrderData?.email || '-'} />

                  <div className="detail-order__renter-detail-body__phone-number">
                    <InputField
                      label="No Handphone"
                      disabled
                      value={
                        oldOrderData?.is_admin_creation ? oldOrderData?.phone_country_code : customer?.phone_code || '-'
                      }
                      icon={
                        <img
                          src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                          width={26}
                          height={16}
                          alt={selectedCountry?.name + ' nation flag'}
                        />
                      }
                    />
                    <InputField value={oldOrderData?.phone_number || '-'} disabled />
                  </div>

                  <InputField label="Negara" disabled value={selectedCountry?.name || '-'} />

                  <div className="detail-order__renter-detail-body__phone-number">
                    <div className="detail-order__renter-detail-body__phone-number-wrapper">
                      <InputField
                        label="Whatsapp"
                        disabled
                        value={
                          oldOrderData?.is_admin_creation
                            ? oldOrderData?.phone_country_code
                            : customer?.phone_code || '-'
                        }
                        icon={
                          <img
                            src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                            width={26}
                            height={16}
                            alt={selectedCountry?.name + ' nation flag'}
                          />
                        }
                      />
                      <div className="detail-order__renter-detail-body__image-wrapper"></div>
                    </div>
                    <InputField disabled value={oldOrderData?.wa_number || '-'} />
                  </div>

                  <div />

                  <div className="detail-order__payment-detail-body__preview-image">
                    <InputField
                      label="KTP"
                      disabled
                      value={
                        data?.is_admin_creation && getFileName(data?.order_detail?.identity?.ktp) !== ''
                          ? getFileName(data?.order_detail?.identity?.ktp)
                          : customer?.PersonalInfos?.ktp
                          ? getFileName(customer?.PersonalInfos?.ktp)
                          : 'Belum Upload KTP'
                      }
                      className="preview-image-input"
                    />
                    {(data?.is_admin_creation && getFileName(data?.order_detail?.identity?.ktp) !== '') ||
                    customer?.PersonalInfos?.ktp ? (
                      <Button
                        bgColor="#D9D9D9"
                        textColor="#000000"
                        className="preview-image-btn"
                        onClick={() =>
                          imagePreviewHandler(
                            data?.is_admin_creation
                              ? data?.order_detail?.identity?.ktp
                              : IMAGE_BASE_URL + customer?.PersonalInfos?.ktp,
                          )
                        }
                      >
                        Lihat
                      </Button>
                    ) : null}
                  </div>

                  <div className="detail-order__payment-detail-body__preview-image">
                    <InputField
                      label="SIM"
                      disabled
                      value={
                        data?.is_admin_creation && getFileName(data?.order_detail?.identity?.sim) !== ''
                          ? getFileName(data?.order_detail?.identity?.sim)
                          : customer?.PersonalInfos?.sim
                          ? getFileName(customer?.PersonalInfos?.sim)
                          : 'Belum Upload SIM'
                      }
                      className="preview-image-input"
                    />
                    {(data?.is_admin_creation && getFileName(data?.order_detail?.identity?.sim) !== '') ||
                    customer?.PersonalInfos?.sim ? (
                      <Button
                        bgColor="#D9D9D9"
                        textColor="#000000"
                        className="preview-image-btn"
                        onClick={() =>
                          imagePreviewHandler(
                            data?.is_admin_creation
                              ? data?.order_detail?.identity?.sim
                              : IMAGE_BASE_URL + customer?.PersonalInfos?.sim,
                          )
                        }
                      >
                        Lihat
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="detail-order__rent-detail">
                <div className="detail-order__rent-detail-title">
                  <h1>Detail Sewa</h1>
                </div>
                <div className="detail-order__rent-detail-body">
                  <InputField
                    label="Lokasi Pengantaran"
                    disabled
                    value={oldOrderData?.order_detail?.rental_delivery_location || '-'}
                  />
                  <InputField
                    label="Detail Lokasi Pengantaran"
                    disabled
                    value={oldOrderData?.order_detail?.rental_delivery_location_detail || '-'}
                  />
                  {oldOrderData?.order_detail?.flight_number ? (
                    <InputField
                      label="Nomor Penerbangan"
                      disabled
                      value={oldOrderData?.order_detail?.flight_number || '-'}
                    />
                  ) : null}
                  {oldOrderData?.order_detail?.landing_time ? (
                    <InputField
                      label="Waktu Mendarat"
                      disabled
                      value={oldOrderData?.order_detail?.landing_time || '-'}
                    />
                  ) : null}

                  <InputField
                    label="Lokasi Pengambilan"
                    disabled
                    value={oldOrderData?.order_detail?.rental_return_location || '-'}
                  />
                  <InputField
                    label="Detail Lokasi Pengambilan"
                    disabled
                    value={oldOrderData?.order_detail?.rental_return_location_detail || '-'}
                  />

                  <InputField label="Tipe Sewa" disabled value={oldOrderData?.order_type_id === 1 ? 'Harian' : '-'} />
                  <InputField label="Jenis Mobil" disabled value={oldOrderData?.order_detail?.vehicle?.name ?? '-'} />

                  <div className="detail-order__rent-detail-body__rent-date">
                    <InputField
                      label="Tanggal Mulai"
                      disabled
                      value={
                        oldOrderData?.order_detail?.start_booking_date
                          ? indonesianDateFormat(oldOrderData?.order_detail?.start_booking_date)
                          : '-'
                      }
                    />
                    <InputField
                      label="Jam Mulai"
                      disabled
                      value={(oldOrderData?.order_detail?.start_booking_time || '-') + ' ' + timezone}
                    />
                  </div>

                  <div className="detail-order__rent-detail-body__rent-date">
                    <InputField
                      label="Tanggal Selesai"
                      disabled
                      value={
                        oldOrderData?.order_detail?.end_booking_date
                          ? indonesianDateFormat(oldOrderData?.order_detail?.end_booking_date)
                          : '-'
                      }
                    />
                    <InputField
                      label="Jam Selesai"
                      disabled
                      value={(oldOrderData?.order_detail?.end_booking_time || '-') + ' ' + timezone}
                    />
                  </div>

                  <div className="detail-order__rent-detail-body__rent-date">
                    <InputField
                      label="Plat Mobil"
                      disabled
                      value={oldOrderData?.order_detail?.vehicle?.license_number ?? '-'}
                    />
                    <InputField
                      label="Jumlah Penumpang"
                      icon={<CarChair />}
                      iconPosition="start"
                      disabled
                      value={
                        oldOrderData?.order_detail?.passenger_number
                          ? `${oldOrderData?.order_detail?.passenger_number} Orang`
                          : '0'
                      }
                    />
                  </div>

                  <div className="detail-order__rent-detail-body__rent-date">
                    <InputField
                      label="Kapasitas Koper"
                      disabled
                      value={oldOrderData?.order_detail?.vehicle?.max_suitcase ?? '-'}
                    />
                    <InputField label="Koper yang dibawa" disabled value={oldOrderData?.order_detail?.baggage ?? 0} />
                  </div>

                  <InputField
                    label="Overtime"
                    value={oldOrderData?.over_time > 0 ? oldOrderData?.over_time : 'Tidak Ada Overtime' || ''}
                    disabled
                    readOnly
                  />
                </div>
              </div>

              <div className="detail-order__voucher-detail">
                <div className="detail-order__voucher-detail-title">
                  <h1>Detail Deposit</h1>
                </div>
                <div className="detail-order__voucher-detail-body">
                  <InputField label="Nama Akun" disabled value={customerAccountBank.nama_rek || '-'} />
                  <InputField label="No Rekening" disabled value={customerAccountBank.no_rek || '-'} />
                  <InputField label="Nama Bank" disabled value={customerAccountBank.nama_bank || '-'} />
                </div>
              </div>

              <AddOnsField
                data={oldOrderData?.addons?.length > 0 ? oldOrderData?.addons : addOnsOrder}
                totalPrice={oldOrderData?.addon_total_price}
              />

              <div className="detail-order__voucher-detail">
                <div className="detail-order__voucher-detail-title">
                  <h1>Voucher</h1>
                </div>
                <div className="detail-order__voucher-detail-body">
                  <InputField label="Nama Voucher" disabled value={oldOrderData?.vouchers?.[0].name || '-'} />
                  <InputField label="Kode Voucher" disabled value={oldOrderData?.vouchers?.[0].code || '-'} />
                  <InputField
                    label="Harga Potongan"
                    disabled
                    value={setCurrency(oldOrderData?.vouchers?.[0].value) || '-'}
                  />
                </div>
              </div>

              <div className="detail-order__price-detail">
                <div className="detail-order__price-detail-title">
                  <h1>Rincian Biaya</h1>
                </div>
                <div className="detail-order__price-detail-body">
                  <InputField
                    label="Biaya Sewa Mobil"
                    disabled
                    value={setCurrency(oldOrderData?.booking_price) || '-'}
                  />
                  <InputField label="Deposit" disabled value={setCurrency(oldOrderData?.deposit) || '-'} />
                  <InputField
                    label="Deposit e-Toll"
                    disabled
                    value={setCurrency(oldOrderData?.deposit_e_toll) || '-'}
                  />
                  <InputField
                    label="Pembayaran Dengan Point"
                    disabled
                    value={oldOrderData?.point}
                    icon={<PointsIcon />}
                    iconPosition="start"
                  />
                  {oldOrderData?.over_time_price > 0 && (
                    <InputField label="Overtime" disabled value={setCurrency(oldOrderData?.over_time_price) || '-'} />
                  )}
                  {oldOrderData?.one_day_order_charge > 0 && (
                    <InputField
                      label="One Day Charge"
                      disabled
                      value={setCurrency(oldOrderData?.one_day_order_charge) || '-'}
                    />
                  )}
                  {oldOrderData?.outside_operational_charge > 0 && (
                    <InputField
                      label="Outside Operational Hour Charge"
                      disabled
                      value={setCurrency(oldOrderData?.outside_operational_charge) || '-'}
                    />
                  )}
                  {oldOrderData?.exceed_passenger_price > 0 && (
                    <InputField
                      label="Exceed Max Passenger Charge"
                      disabled
                      value={setCurrency(oldOrderData?.exceed_passenger_price) || '-'}
                    />
                  )}
                  <InputField
                    label="Biaya Lokasi Pengantaran"
                    disabled
                    value={setCurrency(oldOrderData?.rental_delivery_fee) || '-'}
                  />
                  <InputField
                    label="Biaya Pengembalian"
                    disabled
                    value={setCurrency(oldOrderData?.rental_return_fee) || '-'}
                  />
                  <InputField
                    label="Charge Payment Gateway Platform"
                    disabled
                    value={setCurrency(oldOrderData?.disbursement?.total_amount_external_payment_fee) || '-'}
                  />
                  <InputField
                    label="Customer Service Platform"
                    disabled
                    value={setCurrency(oldOrderData?.disbursement?.total_amount_customer_service_fee) || '-'}
                  />
                </div>
                <div className="detail-order__price-detail-body__input-violation">
                  <InputViolation
                    violationList={[]}
                    setViolationList={setViolationList}
                    totalPrice={oldOrderData?.order_violations
                      ?.map((item) => item.cost)
                      ?.reduce((acc, cVal) => acc + cVal, 0)}
                    violationPrice={0}
                    data={oldOrderData?.order_violations || []}
                    isOnEdit={false}
                  />
                </div>
                <div className="detail-order__price-detail-body__input-violation">
                  <InputAdditionals
                    additionalList={[]}
                    setAdditionalList={setAdditionalList}
                    totalPrice={oldOrderData?.order_additional_fees
                      ?.map((item) => item.value)
                      ?.reduce((acc, cVal) => acc + cVal, 0)}
                    additionalPrice={0}
                    data={oldOrderData?.order_additional_fees || []}
                    isOnEdit={false}
                  />
                </div>
                <div className="detail-order__price-detail-body__payment-type">
                  <div className="detail-order__price-detail-body">
                    <InputField
                      label="Tipe Pembayaran"
                      disabled
                      value={
                        oldOrderData?.type === 'HALF'
                          ? `Pembayaran DP ${
                              oldOrderData?.order_detail?.dp_rule?.formula_percentage?.value
                                ? `${oldOrderData?.order_detail.dp_rule.formula_percentage.value}%`
                                : ''
                            }`
                          : 'Pembayaran Full'
                      }
                    />
                    <InputField
                      label={`Biaya Bayar DP ${
                        oldOrderData?.order_detail?.dp_rule?.formula_percentage?.value
                          ? `${oldOrderData?.order_detail.dp_rule.formula_percentage.value}%`
                          : ''
                      }`}
                      disabled
                      value={oldOrderData?.type === 'HALF' ? setCurrency(oldOrderData?.down_payment) : '-'}
                    />
                    <InputField
                      label="Biaya selanjutnya yang harus dibayar"
                      disabled
                      value={oldOrderData?.type === 'HALF' ? setCurrency(oldOrderData?.remainder) : '-'}
                    />
                    {oldOrderData?.type === 'HALF' ? (
                      <InputField
                        label="Status Pembayaran DP"
                        disabled
                        value={oldOrderData?.payment_time === null ? 'Belum Lunas' : 'Lunas' || '-'}
                      />
                    ) : null}
                  </div>
                </div>

                <div className="detail-order__price-detail-body__payment-total">
                  <div className="detail-order__price-detail-body">
                    <InputField
                      label="Total Keseluruhan"
                      disabled
                      value={setCurrency(oldOrderData?.total_payment) || '-'}
                    />
                  </div>
                </div>
              </div>

              <div className="detail-order__payment-detail">
                <div className="detail-order__payment-detail-title">
                  <h1>Pembayaran</h1>
                </div>
                <div className="detail-order__payment-detail-body">
                  <InputField label="Status Pembayaran" disabled value={data?.order_status || '-'} />

                  <div
                    className={clsx(
                      'detail-order__payment-detail-body__payment-method',
                      data?.order_status === 'CHECKOUT' ? 'confirmation' : 'not-confirmation',
                    )}
                  >
                    <InputField
                      label="Metode Pembayaran"
                      disabled
                      // value={data?.disbursement?.payment?.method ? data?.disbursement.payment.method : '-'}
                      value={getPaymentMethodString(data?.disbursement)}
                    />
                  </div>

                  {data?.order_status !== 'CHECKOUT' &&
                  data?.order_status !== 'RECONFIRMATION' &&
                  Object.prototype.hasOwnProperty.call(data, 'disbursement') ? (
                    <>
                      <InputField
                        label="Nama Rekening"
                        disabled
                        value={
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.sender_name
                            : data?.disbursement?.sender_name || '-'
                        }
                      />

                      <InputField
                        label="Nama Bank"
                        disabled
                        value={
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.sender_bank_name
                            : data?.disbursement?.sender_bank_name || '-'
                        }
                      />

                      <div className="detail-order__payment-detail-body__preview-image">
                        <InputField
                          label="Foto Bukti Transfer"
                          disabled
                          value={getFileName(
                            Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                              ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                              : data?.disbursement?.disbursement_confirmation_image,
                          )}
                          className="preview-image-input"
                        />

                        <Button
                          bgColor="#D9D9D9"
                          textColor="#000000"
                          className="preview-image-btn"
                          onClick={() =>
                            imagePreviewHandler(
                              Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                                : data?.disbursement?.disbursement_confirmation_image,
                            )
                          }
                        >
                          Lihat
                        </Button>
                      </div>

                      <div />
                    </>
                  ) : null}
                </div>
              </div>

              <div className="detail-order__transaction-buttons">
                <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
                  Kembali
                </Button>
              </div>
            </TabPane>
          </Tabs>
        ) : (
          <>
            <div className="detail-order__renter-detail">
              <div className="detail-order__renter-detail-title">
                <h1>Detail Penyewa</h1>
              </div>
              <div className="detail-order__renter-detail-body">
                <InputField label="Nama Lengkap" disabled value={data?.user_name || '-'} />
                <InputField label="Email" disabled value={data?.email || '-'} />

                <div className="detail-order__renter-detail-body__phone-number">
                  <InputField
                    label="No Handphone"
                    disabled
                    value={data?.is_admin_creation ? data?.phone_country_code : customer?.phone_code || '-'}
                    icon={
                      <img
                        src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                        width={26}
                        height={16}
                        alt={selectedCountry?.name + ' nation flag'}
                      />
                    }
                  />
                  <InputField value={data?.phone_number || '-'} disabled />
                </div>

                <InputField label="Negara" disabled value={selectedCountry?.name || '-'} />

                <div className="detail-order__renter-detail-body__phone-number">
                  <div className="detail-order__renter-detail-body__phone-number-wrapper">
                    <InputField
                      label="Whatsapp"
                      disabled
                      value={data?.is_admin_creation ? data?.phone_country_code : customer?.phone_code || '-'}
                      icon={
                        <img
                          src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                          width={26}
                          height={16}
                          alt={selectedCountry?.name + ' nation flag'}
                        />
                      }
                    />
                    <div className="detail-order__renter-detail-body__image-wrapper"></div>
                  </div>
                  <InputField disabled value={data?.wa_number || '-'} />
                </div>

                <div />

                <div className="detail-order__payment-detail-body__preview-image">
                  <InputField
                    label="KTP"
                    disabled
                    value={
                      data?.is_admin_creation && getFileName(data?.order_detail?.identity?.ktp) !== ''
                        ? getFileName(data?.order_detail?.identity?.ktp)
                        : customer?.PersonalInfos?.ktp
                        ? getFileName(customer?.PersonalInfos?.ktp)
                        : 'Belum Upload KTP'
                    }
                    className="preview-image-input"
                  />
                  {data?.order_status !== 'PAID' &&
                  data?.order_status !== 'REVIEWING_IDENTITY' &&
                  ((data?.is_admin_creation && getFileName(data?.order_detail?.identity?.ktp) !== '') ||
                    customer?.PersonalInfos?.ktp) ? (
                    <Button
                      bgColor="#D9D9D9"
                      textColor="#000000"
                      className="preview-image-btn"
                      onClick={() =>
                        imagePreviewHandler(
                          data?.is_admin_creation
                            ? data?.order_detail?.identity?.ktp
                            : IMAGE_BASE_URL + customer?.PersonalInfos?.ktp,
                        )
                      }
                    >
                      Lihat
                    </Button>
                  ) : null}
                  {(data?.order_status === 'PAID' || data?.order_status === 'REVIEWING_IDENTITY') && (
                    <Button
                      type="button"
                      className="validation-identity-btn"
                      onClick={() => setShowReviewIdentityModal({ type: 'ktp', show: true })}
                    >
                      Tinjau
                    </Button>
                  )}
                  {(data?.order_status === 'PAID' || data?.order_status === 'REVIEWING_IDENTITY') &&
                    customer?.PersonalInfos?.need_review_ktp && (
                      <div className="reupload-icon">
                        <ReuploadIcon />
                        <p>Upload ulang KTP</p>
                      </div>
                    )}
                </div>

                <div className="detail-order__payment-detail-body__preview-image">
                  <InputField
                    label="SIM"
                    disabled
                    value={
                      data?.is_admin_creation && getFileName(data?.order_detail?.identity?.sim) !== ''
                        ? getFileName(data?.order_detail?.identity?.sim)
                        : customer?.PersonalInfos?.sim
                        ? getFileName(customer?.PersonalInfos?.sim)
                        : 'Belum Upload SIM'
                    }
                    className="preview-image-input"
                  />
                  {data?.order_status !== 'PAID' &&
                  data?.order_status !== 'REVIEWING_IDENTITY' &&
                  ((data?.is_admin_creation && getFileName(data?.order_detail?.identity?.sim) !== '') ||
                    customer?.PersonalInfos?.sim) ? (
                    <Button
                      bgColor="#D9D9D9"
                      textColor="#000000"
                      className="preview-image-btn"
                      onClick={() =>
                        imagePreviewHandler(
                          data?.is_admin_creation
                            ? data?.order_detail?.identity?.sim
                            : IMAGE_BASE_URL + customer?.PersonalInfos?.sim,
                        )
                      }
                    >
                      Lihat
                    </Button>
                  ) : null}
                  {(data?.order_status === 'PAID' || data?.order_status === 'REVIEWING_IDENTITY') && (
                    <Button
                      type="button"
                      className="validation-identity-btn"
                      onClick={() => setShowReviewIdentityModal({ type: 'sim', show: true })}
                    >
                      Tinjau
                    </Button>
                  )}
                  {(data?.order_status === 'PAID' || data?.order_status === 'REVIEWING_IDENTITY') &&
                    customer?.PersonalInfos?.need_review_sim && (
                      <div className="reupload-icon">
                        <ReuploadIcon />
                        <p>Upload ulang SIM</p>
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className="detail-order__rent-detail">
              <div className="detail-order__rent-detail-title">
                <h1>Detail Sewa</h1>
              </div>
              <div className="detail-order__rent-detail-body">
                <InputField
                  label="Lokasi Pengantaran"
                  disabled
                  value={data?.order_detail?.rental_delivery_location || '-'}
                />
                <InputField
                  label="Detail Lokasi Pengantaran"
                  disabled
                  value={data?.order_detail?.rental_delivery_location_detail || '-'}
                />
                {data?.order_detail?.flight_number ? (
                  <InputField label="Nomor Penerbangan" disabled value={data?.order_detail?.flight_number || '-'} />
                ) : null}
                {data?.order_detail?.landing_time ? (
                  <InputField label="Waktu Mendarat" disabled value={data?.order_detail?.landing_time || '-'} />
                ) : null}

                <InputField
                  label="Lokasi Pengambilan"
                  disabled
                  value={data?.order_detail?.rental_return_location || '-'}
                />
                <InputField
                  label="Detail Lokasi Pengambilan"
                  disabled
                  value={data?.order_detail?.rental_return_location_detail || '-'}
                />

                <InputField label="Tipe Sewa" disabled value={data?.order_type_id === 1 ? 'Harian' : '-'} />
                <InputField label="Jenis Mobil" disabled value={data?.order_detail?.vehicle?.name ?? '-'} />

                <div className="detail-order__rent-detail-body__rent-date">
                  <InputField
                    label="Tanggal Mulai"
                    disabled
                    value={
                      data?.order_detail?.start_booking_date
                        ? indonesianDateFormat(data?.order_detail?.start_booking_date)
                        : '-'
                    }
                  />
                  <InputField
                    label="Jam Mulai"
                    disabled
                    value={(data?.order_detail?.start_booking_time || '-') + ' ' + timezone}
                  />
                </div>

                <div className="detail-order__rent-detail-body__rent-date">
                  <InputField
                    label="Tanggal Selesai"
                    disabled
                    value={
                      data?.order_detail?.end_booking_date
                        ? indonesianDateFormat(data?.order_detail?.end_booking_date)
                        : '-'
                    }
                  />
                  <InputField
                    label="Jam Selesai"
                    disabled
                    value={(data?.order_detail?.end_booking_time || '-') + ' ' + timezone}
                  />
                </div>

                <div className="detail-order__rent-detail-body__rent-date">
                  <InputField label="Plat Mobil" disabled value={data?.order_detail?.vehicle?.license_number ?? '-'} />
                  <InputField
                    label="Jumlah Penumpang"
                    icon={<CarChair />}
                    iconPosition="start"
                    disabled
                    value={data.order_detail?.passenger_number ? `${data?.order_detail?.passenger_number} Orang` : '0'}
                  />
                </div>

                <div className="detail-order__rent-detail-body__rent-date">
                  <InputField
                    label="Kapasitas Koper"
                    disabled
                    value={data?.order_detail?.vehicle?.max_suitcase ?? '-'}
                  />
                  <InputField label="Koper yang dibawa" disabled value={data?.order_detail?.baggage ?? 0} />
                </div>

                <InputField
                  label="Overtime"
                  value={data?.over_time > 0 ? data?.over_time : 'Tidak Ada Overtime' || ''}
                  disabled
                  readOnly
                />
              </div>
            </div>

            <div className="detail-order__voucher-detail">
              <div className="detail-order__voucher-detail-title">
                <h1>Detail Deposit</h1>
              </div>
              <div className="detail-order__voucher-detail-body">
                <InputField label="Nama Akun" disabled value={customerAccountBank.nama_rek || '-'} />
                <InputField label="No Rekening" disabled value={customerAccountBank.no_rek || '-'} />
                <InputField label="Nama Bank" disabled value={customerAccountBank.nama_bank || '-'} />
              </div>
            </div>

            <AddOnsField data={addOnsOrder} totalPrice={data?.addon_total_price} />

            <div className="detail-order__voucher-detail">
              <div className="detail-order__voucher-detail-title">
                <h1>Voucher</h1>
              </div>
              <div className="detail-order__voucher-detail-body">
                <InputField label="Nama Voucher" disabled value={data?.vouchers?.[0].name || '-'} />
                <InputField label="Kode Voucher" disabled value={data?.vouchers?.[0].code || '-'} />
                <InputField label="Harga Potongan" disabled value={setCurrency(data?.vouchers?.[0].value) || '-'} />
              </div>
            </div>

            <div className="detail-order__price-detail">
              <div className="detail-order__price-detail-title">
                <h1>Rincian Biaya</h1>
              </div>
              <div className="detail-order__price-detail-body">
                <InputField label="Biaya Sewa Mobil" disabled value={setCurrency(data?.booking_price) || '-'} />
                <InputField label="Deposit" disabled value={setCurrency(data?.deposit) || '-'} />
                <InputField label="Deposit e-Toll" disabled value={setCurrency(data?.deposit_e_toll) || '-'} />
                <InputField
                  label="Pembayaran Dengan Point"
                  disabled
                  value={data?.point}
                  icon={<PointsIcon />}
                  iconPosition="start"
                />
                {data?.over_time_price > 0 && (
                  <InputField label="Overtime" disabled value={setCurrency(data?.over_time_price) || '-'} />
                )}
                {data?.one_day_order_charge > 0 && (
                  <InputField label="One Day Charge" disabled value={setCurrency(data?.one_day_order_charge) || '-'} />
                )}
                {data?.outside_operational_charge > 0 && (
                  <InputField
                    label="Outside Operational Hour Charge"
                    disabled
                    value={setCurrency(data?.outside_operational_charge) || '-'}
                  />
                )}
                {data?.exceed_passenger_price > 0 && (
                  <InputField
                    label="Exceed Max Passenger Charge"
                    disabled
                    value={setCurrency(data?.exceed_passenger_price) || '-'}
                  />
                )}
                <InputField
                  label="Biaya Lokasi Pengantaran"
                  disabled
                  value={setCurrency(data?.rental_delivery_fee) || '-'}
                />
                <InputField label="Biaya Pengembalian" disabled value={setCurrency(data?.rental_return_fee) || '-'} />
                <InputField
                  label="Charge Payment Gateway Platform"
                  disabled
                  value={setCurrency(data?.disbursement?.total_amount_external_payment_fee) || '-'}
                />
                <InputField
                  label="Customer Service Platform"
                  disabled
                  value={setCurrency(data?.disbursement?.total_amount_customer_service_fee) || '-'}
                />
              </div>
              <div className="detail-order__price-detail-body__input-violation">
                <InputViolation
                  violationList={[]}
                  setViolationList={setViolationList}
                  totalPrice={
                    data?.order_violations?.map((item) => item.cost)?.reduce((acc, cVal) => acc + cVal, 0) ||
                    violationPrice
                  }
                  violationPrice={0}
                  data={data?.order_violations || []}
                  isOnEdit={false}
                />
              </div>
              <div className="detail-order__price-detail-body__input-violation">
                <InputAdditionals
                  additionalList={[]}
                  setAdditionalList={setAdditionalList}
                  totalPrice={
                    data?.order_additional_fees?.map((item) => item.value)?.reduce((acc, cVal) => acc + cVal, 0) ||
                    additionalPrice
                  }
                  additionalPrice={0}
                  data={data?.order_additional_fees || []}
                  isOnEdit={false}
                />
              </div>
              <div className="detail-order__price-detail-body__payment-type">
                <div className="detail-order__price-detail-body">
                  <InputField
                    label="Tipe Pembayaran"
                    disabled
                    value={
                      data.type === 'HALF'
                        ? `Pembayaran DP ${
                            data?.order_detail?.dp_rule?.formula_percentage?.value
                              ? `${data.order_detail.dp_rule.formula_percentage.value}%`
                              : ''
                          }`
                        : 'Pembayaran Full'
                    }
                  />
                  <InputField
                    label={`Biaya Bayar DP ${
                      data?.order_detail?.dp_rule?.formula_percentage?.value
                        ? `${data.order_detail.dp_rule.formula_percentage.value}%`
                        : ''
                    }`}
                    disabled
                    value={data.type === 'HALF' ? setCurrency(data.down_payment) : '-'}
                  />
                  <InputField
                    label="Biaya selanjutnya yang harus dibayar"
                    disabled
                    value={data.type === 'HALF' ? setCurrency(data.remainder) : '-'}
                  />
                  {data.type === 'HALF' ? (
                    <InputField
                      label="Status Pembayaran DP"
                      disabled
                      value={data?.payment_time === null ? 'Belum Lunas' : 'Lunas' || '-'}
                    />
                  ) : null}
                  <InputField
                    label="Total Harga Bersih"
                    disabled
                    value={setCurrency(data?.disbursement?.total_net_amount) || '-'}
                  />
                  <InputField
                    label="Total Bruto"
                    disabled
                    value={setCurrency(data?.disbursement?.total_amount_with_customer_service_fee) || '-'}
                  />
                </div>
              </div>
              <div className="detail-order__price-detail-body__payment-total">
                <div className="detail-order__price-detail-body">
                  <InputField label="Total Keseluruhan" disabled value={setCurrency(data.total_payment) || '-'} />
                  {Object.prototype.hasOwnProperty.call(data, 'order_approval_history') ? (
                    <InputField
                      label="Harga Selisih"
                      disabled
                      value={setCurrency(data?.total_diff_price_with_approval) || '-'}
                    />
                  ) : null}
                </div>
              </div>
            </div>

            <div className="detail-order__payment-detail">
              <div className="detail-order__payment-detail-title">
                <h1>Pembayaran</h1>
              </div>
              <div className="detail-order__payment-detail-body">
                <InputField label="Status Pembayaran" disabled value={data?.order_status || '-'} />

                <div
                  className={clsx(
                    'detail-order__payment-detail-body__payment-method',
                    data.order_status === 'CHECKOUT' ? 'confirmation' : 'not-confirmation',
                  )}
                >
                  <InputField
                    label="Metode Pembayaran"
                    disabled
                    // value={data?.disbursement?.payment?.method ? data?.disbursement.payment.method : '-'}
                    value={getPaymentMethodString(data.disbursement)}
                  />
                  {data.order_status === 'CHECKOUT' &&
                  data?.order_approval_status !== 'WAITING_APPROVAL_DELETE_ORDER' ? (
                    <Button height={44} onClick={() => setShowBankTransferConfirmation(true)} disabled={isPaymentValid}>
                      Konfirmasi
                    </Button>
                  ) : null}
                </div>

                {data.order_status !== 'CHECKOUT' &&
                data.order_status !== 'RECONFIRMATION' &&
                Object.prototype.hasOwnProperty.call(data, 'disbursement') ? (
                  <>
                    <InputField
                      label="Nama Rekening"
                      disabled
                      value={
                        Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                          ? [...data.disbursement_reconfirmation].pop()?.sender_name
                          : data?.disbursement?.sender_name || '-'
                      }
                    />

                    <InputField
                      label="Nama Bank"
                      disabled
                      value={
                        Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                          ? [...data.disbursement_reconfirmation].pop()?.sender_bank_name
                          : data?.disbursement?.sender_bank_name || '-'
                      }
                    />

                    <div className="detail-order__payment-detail-body__preview-image">
                      <InputField
                        label="Foto Bukti Transfer"
                        disabled
                        value={getFileName(
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                            : data?.disbursement?.disbursement_confirmation_image,
                        )}
                        className="preview-image-input"
                      />

                      <Button
                        bgColor="#D9D9D9"
                        textColor="#000000"
                        className="preview-image-btn"
                        onClick={() =>
                          imagePreviewHandler(
                            Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                              ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                              : data?.disbursement?.disbursement_confirmation_image,
                          )
                        }
                      >
                        Lihat
                      </Button>
                    </div>

                    <div />
                  </>
                ) : null}
              </div>
            </div>

            <div className="detail-order__transaction-buttons">
              <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
                Kembali
              </Button>

              {(data.order_status === 'CHECKOUT' || data.order_status === 'REVIEWING_IDENTITY') &&
              !data.order_approval_status ? (
                <Button
                  size="sm"
                  className="button"
                  width={208}
                  variant="danger"
                  onClick={() => setShowRejectOrderModal(true)}
                >
                  Tolak Orderan
                </Button>
              ) : null}

              <RenderedProcessButton />
            </div>
          </>
        )}
      </TableWrapper>
      {showPriceDifference &&
        ReactDOM.createPortal(
          <PriceDifferenceModal
            setShowPriceDifference={setShowPriceDifference}
            Icon={CarIcon}
            title="Lepas Kunci"
            data={data}
          />,
          document.getElementById('modal'),
        )}
    </div>
  );
};

export default DetailOrder;
